import { memo, useState, useEffect, ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { CloseOutlined } from '@ant-design/icons'
import { Drawer, Popconfirm, Row } from 'antd'
import { TableColumnIcon, Text, T4, Space } from '@signifyd/components'
import { isEqual, partition } from 'lodash'
import ResponsiveIconButton from 'core/components/ResponsiveIconButton'
import { useStoreActions, useStoreState } from 'store'
import { defaultSortState } from 'store/search/search.store'
import { ColumnConfig, getDrawerColumns } from './columns'
import TableColumnListItem from './TableColumnListItem'
import HiddenColumnsEmpty from './HiddenColumnsEmpty'
import TableColumnSelectFooter from './TableColumnSelectFooter'
import styles from './TableColumnSelectDrawer.less'

interface Props {
  hasAirlineOrders?: boolean
}

const TableColumnSelectDrawer = ({ hasAirlineOrders }: Props): JSX.Element => {
  const { t } = useTranslation()

  const { sort } = useStoreState((state) => state.search)
  const { setSortOrder } = useStoreActions((actions) => actions.search)

  const { hiddenColumns } = useStoreState((state) => state.user)
  const { updateUserUIState } = useStoreActions((actions) => actions.user)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [columns, setColumns] = useState<Array<ColumnConfig>>(
    getDrawerColumns(!!hasAirlineOrders, hiddenColumns)
  )

  useEffect(() => {
    setColumns(getDrawerColumns(!!hasAirlineOrders, hiddenColumns))
  }, [hiddenColumns, hasAirlineOrders])

  const hasChanges = useMemo(
    () => isEqual(getDrawerColumns(!!hasAirlineOrders, hiddenColumns), columns),
    [columns, hiddenColumns, hasAirlineOrders]
  )

  const displayColumnNames = (
    displayColumns: Array<ColumnConfig>
  ): Array<ReactElement> =>
    displayColumns.map((column) => (
      <TableColumnListItem
        title={column.key}
        isLocked={column.isLocked}
        isHidden={column.isHidden}
        onToggleHidden={() => {
          const newColumns = [...columns]

          newColumns[columns.indexOf(column)].isHidden = !column.isHidden
          setColumns(newColumns)
        }}
        key={column.key}
      />
    ))

  const applyChanges = (): void => {
    const newHiddenColumns = columns
      .filter((column) => column.isHidden)
      .map((column) => column.key)

    updateUserUIState({ searchHiddenColumns: newHiddenColumns })

    // if hiding currently sorted column then reset sort order
    if (newHiddenColumns.includes(sort.by)) {
      setSortOrder(defaultSortState)
    }

    setDrawerVisible(false)
  }

  const conditionallyClear = (): void => {
    if (hasChanges) {
      setDrawerVisible(false)
    }
  }

  const clearChanges = (): void => {
    setColumns(getDrawerColumns(!!hasAirlineOrders, hiddenColumns))
    setDrawerVisible(false)
  }

  const [excludes, includes] = partition(columns, (column) => column.isHidden)

  return (
    <>
      <ResponsiveIconButton
        data-test-id="open-columns-icon"
        data-analytics-id="open-column-config"
        text={
          excludes.length === 0
            ? t('pages.results.columnSelect.drawerTitle')
            : t('pages.results.columnSelect.drawerTitleHidden', {
                count: excludes.length,
              })
        }
        onClick={() => setDrawerVisible(true)}
        Icon={<Icon component={TableColumnIcon} />}
      />
      <Drawer
        styles={{
          body: { paddingRight: '32px', paddingTop: '40px' },
          header: { paddingRight: '32px' },
        }}
        data-test-id="column-select-drawer"
        title={
          <Row>
            <Text rootClassName={styles.drawerTitle} weight="semibold">
              {t('pages.results.columnSelect.drawerTitle')}
            </Text>
            <Popconfirm
              overlayClassName="sig-popconfirm"
              data-test-id="drawer-close-confirm-top"
              icon={null}
              title={
                <>
                  <T4>{t('pages.results.columnSelect.close.confirmTitle')}</T4>
                  <Text type="secondary">
                    {t('pages.results.columnSelect.close.confirmText')}
                  </Text>
                </>
              }
              onConfirm={clearChanges}
              disabled={hasChanges}
              placement="topRight"
              okText={t('pages.results.columnSelect.close.confirmClose')}
              cancelText={t('pages.results.columnSelect.close.no')}
            >
              <CloseOutlined onClick={conditionallyClear} />
            </Popconfirm>
          </Row>
        }
        width={520}
        open={drawerVisible}
        closable={false}
      >
        {/* Visible Columns */}
        <T4 rootClassName={styles.columnTitle}>
          {t('pages.results.columnSelect.visibleColumns')}
        </T4>

        {displayColumnNames(includes)}

        {/* Hidden Columns */}
        <Space size="lg" />
        <T4 rootClassName={styles.columnTitle}>
          {t('pages.results.columnSelect.hiddenColumns')}
        </T4>

        {!excludes.length ? (
          <HiddenColumnsEmpty />
        ) : (
          displayColumnNames(excludes)
        )}

        <Space size={60} />

        <TableColumnSelectFooter
          columns={columns}
          setColumns={setColumns}
          clearChanges={clearChanges}
          applyChanges={applyChanges}
          hasChanges={hasChanges}
          conditionallyClear={conditionallyClear}
          hasAirlineOrders={hasAirlineOrders}
        />
      </Drawer>
    </>
  )
}

export default memo(TableColumnSelectDrawer)
